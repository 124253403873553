<template>
  <div class="media-list-container">
    <div class="media-child-part media__title">
      <router-link
        tag="p"
        :to="{
          path: '/awards'
        }"
        class="scrollObj"
        >awards</router-link
      >
      <router-link
        tag="p"
        :to="{
          path: '/news'
        }"
        class="scrollObj"
        ref="compare"
        >news</router-link
      >
    </div>
    <div class="media-child-part part-main-body">
      <div class="awards-list-container" ref="sb">
        <div class="award__item" v-for="award in lists" :key="award.year">
          <div class="award__year scrollObj">
            <p>{{ award.year }}</p>
          </div>
          <div class="award__content scrollObj">
            <div
              class="content__item scrollObj"
              v-for="(item, index) in award.list"
              :key="index"
              @click="onJump(item.project)"
              :style="{
                cursor: item.project ? 'pointer' : 'auto'
              }"
            >
              <p>{{ item.title }}</p>
              <p>
                <strong>{{
                  item.project ? item.project.name.cn : "F.G STUDIO"
                }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "page-awards-list",
  data() {
    return {
      lists: [],
      compare: 0
    };
  },
  watch: {
    $route: {
      handler: "init",
      immediate: true
    }
  },
  methods: {
    ...mapMutations(["PAGE_LOADING", "PAGE_LOADED"]),
    init() {
      const that = this;
      that.PAGE_LOADING();
      that.getAwards().then(res => {
        const data = res.data;
        that.lists = data;
        that.$nextTick(() => {
          that.PAGE_LOADED();
          const compare = that.$refs.compare.$el;
          that.compare = compare.getBoundingClientRect();
          window.addEventListener("scroll", that.onScroll);
        });
      });
    },
    getAwards() {
      return this.$request.get("/api/awards/list");
    },
    onJump(project) {
      if (project) {
        this.$router.push({
          path: `/project/${project.id}`
        });
      }
    },
    onScroll() {
      const that = this;
      if (!that.$isMobile) {
        const obj = that.$refs.sb;
        const bcr = obj.getBoundingClientRect();
        const offset =
          parseInt(
            window
              .getComputedStyle(obj, null)
              .getPropertyValue("padding-bottom")
          ) +
          parseInt(
            window
              .getComputedStyle(document.querySelector(".award__item"))
              .getPropertyValue("margin-bottom")
          );

        if (bcr.bottom < that.compare.bottom + offset) {
          that.$refs.compare.$el.style.cssText = `bottom: ${that.$mobileScreenHeight -
            that.compare.bottom +
            that.compare.height -
            40 +
            (that.$mobileScreenHeight - bcr.bottom)}px`;
        } else {
          that.$refs.compare.$el.style.cssText = `bottom: ${that.$$mobileScreenHeight -
            that.compare.bottom}`;
        }
      }
    }
  }
};
</script>

<style></style>
